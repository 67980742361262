import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Главная | Zayats Studio
			</title>
			<meta name={"description"} content={"Возвысьте свои моменты"} />
			<meta property={"og:title"} content={"Главная | Zayats Studio"} />
			<meta property={"og:description"} content={"Возвысьте свои моменты"} />
			<meta property={"og:image"} content={"https://venturixpro.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://venturixpro.com/img/3997786.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://venturixpro.com/img/3997786.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://venturixpro.com/img/3997786.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://venturixpro.com/img/3997786.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://venturixpro.com/img/3997786.png"} />
			<meta name={"msapplication-TileImage"} content={"https://venturixpro.com/img/3997786.png"} />
			
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="quarklycommunityKitMobileSidePanelOverride" />
		</Components.Header>
		<Section padding="160px 0 170px 0" background="linear-gradient(180deg,rgba(36, 36, 36, 0.4) 0%,rgba(28, 28, 28, 0.8) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://venturixpro.com/img/1.jpg) 0% 0% /cover repeat scroll padding-box" md-padding="80px 0 90px 0" quarkly-title="Hero-8">
			<Box
				min-width="100px"
				min-height="100px"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				align-items="center"
			>
				<Text
					margin="0px 0px 20px 0px"
					font="normal 700 64px/1.2 --fontFamily-sansTrebuchet"
					sm-font="normal 700 42px/1.2 --fontFamily-sans"
					padding="0px 100px 0px 100px"
					text-align="center"
					color="--light"
					lg-padding="0px 0 0px 0"
				>
					Zayats Studio
				</Text>
				<Text
					margin="0px 0px 50px 0px"
					font="--lead"
					color="#d4dae0"
					padding="0px 50px 0px 50px"
					text-align="center"
					lg-padding="0px 0 0px 0"
				>
					Добро пожаловать в место, где каждый снимок рассказывает свою историю. Независимо от того, хотите ли вы запечатлеть откровенные воспоминания, профессиональные портреты или творческие сцены, наша студия обеспечит идеальный фон для ваших фотографических устремлений. Благодаря команде увлеченных фотографов и современным технологиям мы превращаем каждую сессию в незабываемый опыт.
				</Text>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					sm-flex-direction="column"
					sm-align-self="stretch"
				>
					<Button
						margin="0px 15px 0px 0px"
						padding="12px 28px 12px 28px"
						background="--color-orange"
						border-radius="8px"
						font="normal 400 17px/1.5 --fontFamily-sans"
						sm-margin="0px 0 15px 0px"
						transition="background-color 0.2s ease 0s"
						border-width="2px"
						border-style="#3f24d8"
						border-color="#3f24d8"
						hover-background="rgba(63, 36, 216, 0)"
						href="/contacts"
						type="link"
						text-decoration-line="initial"
						sm-text-align="center"
					>
						Связаться с нами
					</Button>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-darkL2">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="block"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 0px 0px" color="--orange" font="--lead" md-margin="0px 0px 20px 0px">
					Zayats Studio
				</Text>
				<Text margin="0px 0px 0px 0px" color="--light" font="--headline2" md-margin="0px 0px 30px 0px">
					О нас
				</Text>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 40px 0px" color="--light" font="--base">
				В студии Zayats мы верим в магию мгновений. Наши фотографы не просто умеют обращаться с камерой - они художники, понимающие нюансы освещения, настроения и выражения лица. От семейных съемок до редакционных статей о высокой моде - наша студия готова удовлетворить все ваши потребности в фотосъемке с творческим подходом и профессионализмом.
				</Text>
			</Box>
		</Section>
		<Section padding="40px 0 40px 0" background="--color-darkL2" quarkly-title="Description-11">
			<Override
				slot="SectionContent"
				flex-wrap="wrap"
				max-width="1440px"
				align-items="flex-start"
				flex-direction="row"
			/>
			<Box
				margin="30px 0px 30px 0px"
				lg-margin="56px 0px 30px 0px"
				md-display="none"
				width="33.333%"
				padding="20px 20px 20px 20px"
			>
				<Image
					src="https://venturixpro.com/img/2.jpg"
					display="block"
					max-width="330px"
					width="100%"
					height="330px"
					object-fit="cover"
				/>
			</Box>
			<Box
				sm-width="100%"
				width="33.333%"
				padding="20px 20px 20px 20px"
				margin="30px 0px 30px 0px"
				lg-margin="56px 0px 30px 0px"
				md-width="50%"
				sm-margin="20px 0px 20px 0px"
			>
				<Text margin="0px 0px 0px 0px" font="--headline3" color="--light">
				Профессиональное качество
				</Text>
				<Text margin="40px 0px 0px 0px" font="--base" color="--light">
				изображения высокого разрешения, на которых запечатлена каждая деталь.
				</Text>
			</Box>
			<Box
				md-width="50%"
				sm-margin="20px 0px 20px 0px"
				sm-width="100%"
				width="33.333%"
				padding="20px 20px 20px 20px"
				margin="30px 0px 50px 0px"
				lg-margin="56px 0px 30px 0px"
			>
				<Text margin="0px 0px 0px 0px" font="--headline3" color="--light">
				Творческая свобода
				</Text>
				<Text font="--base" color="--light" margin="40px 0px 0px 0px">
				Разнообразные тематические фоны и реквизит для раскрытия вашего творческого потенциала.
				</Text>
			</Box>
			<Box
				md-width="50%"
				sm-margin="20px 0px 20px 0px"
				sm-width="100%"
				width="33.333%"
				padding="20px 20px 20px 20px"
				margin="30px 0px 50px 0px"
				lg-margin="56px 0px 30px 0px"
			>
				<Text color="--light" margin="0px 0px 0px 0px" font="--headline3">
				Индивидуальный подход
				</Text>
				<Text margin="40px 0px 0px 0px" font="--base" color="--light">
				Индивидуальные сессии, учитывающие ваш уникальный стиль и предпочтения.
				</Text>
			</Box>
			<Box
				margin="30px 0px 30px 0px"
				lg-margin="56px 0px 30px 0px"
				md-display="none"
				width="33.333%"
				padding="20px 20px 20px 20px"
			>
				<Image
					src="https://venturixpro.com/img/3.jpg"
					display="block"
					max-width="330px"
					width="100%"
					height="330px"
					object-fit="cover"
				/>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-6" background="--color-darkL2">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="45%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Box
					width="100%"
					height="auto"
					overflow-y="hidden"
					hover-transform="translateY(-10px)"
					padding="0px 0px 125% 0px"
					overflow-x="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						top={0}
						right={0}
						src="https://venturixpro.com/img/4.jpg"
						display="block"
						width="100%"
						bottom={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
						left={0}
					/>
				</Box>
			</Box>
			<Box
				lg-order="1"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				display="flex"
				width="55%"
				flex-direction="column"
				justify-content="space-between"
			>
				<Box
					height="auto"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 45% 0px"
					width="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						display="block"
						top="auto"
						right={0}
						bottom="0px"
						src="https://venturixpro.com/img/5.jpg"
						object-fit="cover"
						left={0}
						min-height="100%"
						position="absolute"
						width="100%"
						object-position="0%"
					/>
				</Box>
				<Box
					position="relative"
					padding="0px 0px 45% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
				>
					<Image
						object-fit="cover"
						width="100%"
						bottom="0px"
						min-height="100%"
						src="https://venturixpro.com/img/6.jpg"
						position="absolute"
						display="block"
						top="auto"
						left={0}
						right={0}
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"652fe240cd81ee001f7dd8c4"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});